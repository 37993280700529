<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.update_service") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit(true)"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-form ref="form" :model="form" :rules="rules" class="aticler_m">
      <el-row :gutter="20">
        <el-col :sm="12">
          <el-form-item :label="columns.name.title" prop="name">
            <el-input v-model="form.name" autocomplete="off"/>
          </el-form-item>
        </el-col>

        <!-- end col -->
        <el-col :sm="12">
          <el-form-item :label="columns.parent_id.title" prop="parent_id">
            <select-service
              :service_id="form.parent_id"
              v-model="form.parent_id"
              @input="getValue"
            />
          </el-form-item>
        </el-col>
        <!-- end col -->

        <el-col :sm="12">
          <el-form-item :label="columns.code.title" prop="code">
            <el-input v-model="form.code" autocomplete="off"/>
          </el-form-item>
        </el-col>
        <!-- end col -->

        <el-col :sm="12">
          <el-form-item :label="columns.price.title">
            <div class="el-input el-input--small">
              <el-input
                v-model="form.price"
                :placeholder="columns.price.title"
              />
            </div>
          </el-form-item>
        </el-col>

        <el-col :sm="12">
          <el-form-item :label="columns.resident_price.title">
            <div class="el-input el-input--small">
              <money
                v-model="form.resident_price"
                v-bind="money"
                class="el-input__inner"
              />
            </div>
          </el-form-item>
        </el-col>

        <el-col :sm="12">
          <el-form-item :label="columns.discount_price.title">
            <div class="el-input el-input--small">
              <el-input v-model="form.discount_price"/>
            </div>
          </el-form-item>
        </el-col>

        <el-col :sm="12">
          <el-form-item :label="columns.service_category.title">
            <select-service-category
                :category_id="form.category_id"
                v-model="form.category_id"
            />
          </el-form-item>
        </el-col>

        <el-col :sm="12">
          <el-form-item :label="columns.life_time.title">
            <div class="el-input el-input--small">
              <el-input
                type="number"
                step="any"
                :min="0"
                :max="100"
                size="medium"
                v-model="form.life_time"
              />
            </div>
          </el-form-item>
        </el-col>

        <el-col :sm="4">
          <el-form-item>
            <div class="el-input el-input--small ser_chek">
              <el-checkbox
                  v-model="form.unnecessary_enclosure"
                  :label="$t('message.unnecessary_enclosure')"
                  border
                  size="medium"
              />
            </div>
          </el-form-item>
        </el-col>

        <el-col :span="4">
          <el-form-item>
            <div class="el-input el-input--small ser_chek">
              <el-checkbox
                v-model="form.is_parent"
                :label="$t('message.parent')"
                border
                size="medium"
              />
            </div>
          </el-form-item>
        </el-col>

        <el-col :span="4">
          <el-form-item>
            <div class="el-input el-input--small ser_chek">
              <el-checkbox
                v-model="form.is_physio"
                :label="$t('message.procedural')"
                border
                size="medium"
              />
            </div>
          </el-form-item>
        </el-col>

        <el-col :span="4">
          <el-form-item>
            <div class="el-input el-input--small ser_chek">
              <el-checkbox
                v-model="form.is_laboratory"
                :label="$t('message.laboratory')"
                border
                size="medium"
              />
            </div>
          </el-form-item>
        </el-col>

        <el-col :span="4">
          <el-form-item>
            <div class="el-input el-input--small ser_chek">
              <el-checkbox
                v-model="form.letterhead"
                :label="$t('message.letterhead')"
                border
                size="medium"
              />
            </div>
          </el-form-item>
        </el-col>

        <el-col :span="4">
          <el-form-item>
            <div class="el-input el-input--small ser_chek">
              <el-checkbox
                v-model="form.is_report"
                :label="$t('message.reports')"
                border
                size="medium"
              />
            </div>
          </el-form-item>
        </el-col>

        <el-col :sm="4">
          <el-form-item>
            <div class="el-input el-input--small ser_chek">
              <el-checkbox
                  v-model="form.for_outsource"
                  :label="$t('message.for_outsource')"
                  border
                  size="medium"
              />
            </div>
          </el-form-item>
        </el-col>

        <el-col :sm="4">
          <el-form-item>
            <div class="el-input el-input--small ser_chek">
              <el-checkbox
                  v-model="form.enabled"
                  :label="$t('message.enabled')"
                  border
                  size="medium"
                  :checked="form.enabled"
              />
            </div>
          </el-form-item>
        </el-col>

        <el-col :sm="4">
          <el-form-item>
            <div class="el-input el-input--small ser_chek">
              <el-checkbox
                  v-model="form.auto_write_off"
                  :label="$t('message.auto_write_off')"
                  border
                  size="medium"
              />
            </div>
          </el-form-item>
        </el-col>

        <el-col :span="4">
          <div class="block">
            <span>{{ $t("message.color") }}</span>
            <el-color-picker
              v-loading="loadingData"
              v-model="color"
            />
          </div>
        </el-col>
      </el-row>


      <el-collapse>
        <el-collapse-item :title="$t(`message.form_template_and_signs`)" name="form template and signs">
          <el-row :gutter="20">
            <el-col :sm="8">
              <el-form-item
                :label="columns.form_template_id.title"
                prop="form_template_id"
              >
                <el-select
                  popper-class="khan_se"
                  v-model="form.form_template_id"
                  :placeholder="columns.form_template_id.title"
                  filterable
                  remote
                  clearable
                  :remote-method="searchFormTemplateWithLoad"
                  :loading="loadingDataFromTemplate"
                >
                  <el-option
                    v-for="item in formTemplate"
                    :key="item.id + item.name"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :sm="8">
              <div v-for="(sign, index) in signs" :key="index">
                <el-row :gutter="20">
                  <el-col :sm="8">
                    <el-form-item :label="$t('message.name')">
                      <el-input v-model="sign.name"/>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="10">
                    <el-form-item :label="$t('message.form_template_sign')">
                      <el-input v-model="sign.sign"/>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="3">
                    <el-button
                      size="small"
                      icon="el-icon-delete"
                      style="height: 38px; margin-top: 22px"
                      type="danger"
                      @click.prevent="removeSign(index)"
                    >{{ $t("message.clear") }}</el-button
                    >
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :sm="10">
              <div
                class="plusic mt-3"
                @click="addSign()"
                style="height: 10%; width: 5%"
              >
                <i class="el-icon-plus"/>
              </div>
            </el-col>
          </el-row>
        </el-collapse-item>
        <el-collapse-item v-if="form.auto_write_off" :title="$t(`message.write_off_items`)" name="write_off_items">
          <el-row :gutter="20">
            <el-col :sm="24">
              <el-card class="box-card mb-4">
                <div>
                  <el-table border :data="form.items" :row-class-name="tableRowClassName">
                    <el-table-column label="#" width="50">
                      <template slot-scope="scope">{{ scope.$index + 1 }}</template>
                    </el-table-column>
                    <el-table-column :label="$t('message.name')">
                      <template slot-scope="scope">{{ scope.row.product.name }}</template>
                    </el-table-column>
                    <el-table-column :label="$t('message.quantity')">
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.quantity" @input="correctNumber(scope.row, 'quantity')"></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('message.actions')">
                      <template slot-scope="scope">
                        <el-button
                            type="danger"
                            icon="el-icon-delete"
                            circle
                            @click="removeWriteOffItem(scope.row)"
                        ></el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>

                <el-row :gutter="25">
                  <el-col :span="11"  class="mt-3">
                    <el-form-item :label="$t('message.products')">
                      <products @append="append" v-model="form.product_id" :product_id="form.product_id"></products>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
        </el-collapse-item>
      </el-collapse>
      <!-- end row -->
    </el-form>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import drawerChild from "@/utils/mixins/drawer-child";
import selectService from "@/components/inventory-select/select-service";
import SelectServiceCategory from "@/components/inventory-select/select-service-category.vue";
import products from "@/components/selects/products.vue";

export default {
  mixins: [form, drawerChild],
  props: ["selected", "reloadModel"],
  components: {
    products,
    SelectServiceCategory,
    selectService,
  },
  data() {
    return {
      color: "",
      options: [],
      selected_parent_id: [],
      loadingData: false,
      loadingDataFromTemplate: false,
      signs: [
        {
          name: "",
          sign: "",
        },
      ],
      value: '',
    };
  },
  watch: {
    reloadModel: {
      handler: function () {
        if (this.reloadModel) {
          this.loadModel();
        }
      },
      deep: true,
    },
  },
  async create() {
    await this.fetchData();
  },
  async mounted() {
    if (this.categories && this.categories.length === 0)
      await this.loadCategories();

    this.updateForm();
  },
  computed: {
    ...mapGetters({
      services: "services/list",
      rules: "services/rules",
      model: "services/model",
      formTemplate: "formTemplate/list",
      columns: "services/columns",
      categories: "categoryServices/list",
      money: "money",
    }),
  },
  methods: {
    ...mapActions({
      save: "services/update",
      editModel: "services/show",
      formTemplateIndex: "formTemplate/index",
      loadCategories: "categoryServices/index",
      colorServices: "services/color",
    }),
    loadModel() {
      this.form = JSON.parse(JSON.stringify(this.model));
    },
    getValue() {
      if (this.selected_parent_id.length) {
        let color = this.selected_parent_id.filter(
          (val) => val.id == this.form.parent_id
        );

        this.color = color.length ? color[0].color : "";
      } else {
        this.color = "";
      }
    },
    searchFormTemplateWithLoad(val) {
      this.loadingDataFromTemplate = true;
      this.searchFormTemplate(val);
    },
    searchFormTemplate: _.debounce(function (val) {
      this.formTemplateIndex({ search: val })
        .then((res) => {
          this.loadingDataFromTemplate = false;
        })
        .catch((err) => {
          this.loadingDataFromTemplate = false;
        });
    }, 500),
    async afterOpened() {
      if (this.selected) {
        await this.editModel(this.selected.id)
          .then(async (res) => {
            this.form = JSON.parse(JSON.stringify(this.model));
            this.signs = this.form.signs;
            this.formTemplateIndex({ id: this.form.form_template_id });
            this.form.unnecessary_enclosure = res.data.service.unnecessary_enclosure;
            this.form.category_id = res.data.service.category_id;
            this.updateForm();
          })
          .catch((err) => {
            this.$notify({
              title: "РћС€РёР±РєР°",
              type: "error",
              offset: 130,
              message: err.error.message,
            });
          });
      }
    },
    afterClosed() {
      this.selected_parent_id = [];
      this.resetForm("form");
      this.empty();
      this.form = JSON.parse(JSON.stringify());
    },
    removeSign(index) {
      this.signs.splice(index, 1);
    },
    addSign() {
      this.signs.push({
        name: "",
        sign: "",
      });
    },
    async updateForm() {},
    fetchData() {
      this.loadingData = true;
      const query = { selected_relation: true };
      this.colorServices(query)
        .then((res) => {
          this.loadingData = false;
          this.selected_parent_id = res.data.services.data;
        })
        .catch((err) => {});
    },

    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.form.signs = this.signs;
          this.form.color = this.color;
          // this.form.items = this.items;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$notify({
                title: "Успешно",
                type: "success",
                offset: 60,
                message: res.message,
              });
              this.parent().listChanged();

              this.resetForm("form");
              this.close();
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$notify({
                title: "РћС€РёР±РєР°",
                type: "error",
                offset: 130,
                message: err.error.message,
              });
            });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.reloadForm();
      this.$emit("c-close", { drawer: "drawerUpdate" });
      this.close();
    },
    append(product) {
      this.loadingItems = true;
      console.log(this.form);
      this.form.items.push({
        product_id: product.id,
        quantity: '',
        product: {
          name: product.name,
        },
      });
    },
    removeWriteOffItem(item) {
      const index = this.form.items.indexOf(item);
      this.form.items.splice(index, 1);
    },
    correctNumber(obj, propertyName) {
      let unformattedValue = obj[propertyName].toString().replace(/\s/g, '');
      let formattedValue = unformattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

      this.$set(obj, propertyName, formattedValue);
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.available == 0) {
        return 'warning-row';
      }
    },
  },
};
</script>
<style >
.khan_se .el-select-dropdown__item {
  width: 600px;
  overflow: initial;
  height: inherit;
  white-space: initial;
}

.ser_chek {
  margin-top: 18px;
}

.ser_chek label.el-checkbox {
  width: 100%;
}
.servis-pratses {
  background: #e1e8f1;
  padding: 10px;
  padding-bottom: 3px;
  border-radius: 4px;
  display: flex;
  margin-bottom: 10px;
}
.servis-pratses h5 {
  min-width: 250px;
  margin-left: 5px;
  font-size: 14px;
}
.el-input__inner2 {
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
.el-input__inner2:disabled {
  background: #f6f8fb;
}
</style>